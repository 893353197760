import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'user',
    initialState: {user: {}},
    reducers:{
        getUser: (state,action)=>{
            state.user = action.payload
        }
    }
})

export const getMyUser = (id) => apiCall({
    url: 'users/'+id,
    method: 'GET',
    onSuccess: slice.actions.getUser.type
})


export default slice.reducer