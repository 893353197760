import * as React from 'react';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {connect} from "react-redux";
import {cartMsg, deleteCart, editMyCart, getAllCart, messageReset} from "../../store/cart";
import {getMyUser} from "../../store/User";
import {getAllOrders, ordersSave} from "../../store/Orders";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {useEffect, useState} from "react";
import CloseButton from "react-bootstrap/CloseButton";
import {useTelegram} from "../useTelegram";
import {styled, Tooltip, tooltipClasses} from "@mui/material";
import {useTranslation} from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function CartModal(props) {
    const {tg, userTg, onClose} = useTelegram()
    const {t, i18n} = useTranslation()
    const [myUser, setMyUser] = useState(false);
    const [open, setOpen] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [cart, setCart] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        props.getAllCart(userTg ? userTg.id : '11')
        props.getMyUser(userTg ? userTg.id : '11')
    }, [])

    useEffect(() => {
        if (!cart.length) {
            handleClose()
        }
        if (props.orders.message) {
            onClose()
        }

    }, [props])

    useEffect(() => {
        if (props.cart.message == 'deleted') {
            props.getAllCart(userTg ? userTg.id : '11')
        }
        if (!props.cart.cart) {
            props.setIsOpen(false)
        }
        let myModalCart = props.cart.cart
        setCart(myModalCart)
        props.setCartApp(myModalCart)
    }, [props.cart])

    useEffect(() => {
        setMyUser(props.user.user)
    }, [props.user])


    function getPrice(item) {
        let sum_price = 0
        sum_price += item.item.price * Number(item.number_of_product);
        return sum_price
    }


    function AddQuantity(product_item) {
        let newNum = product_item['number_of_product']
        newNum++
        props.editMyCart({...product_item, number_of_product: newNum})
    }

    function RemoveQuantity(product_item) {
        let newNum = product_item['number_of_product']
        newNum--
        props.editMyCart({...product_item, number_of_product: newNum})
    }

    function handleClose() {
        props.setIsOpen(false);
        setOpen(false);
    }

    function deleteProduct(item) {
        props.deleteCart(item)
    }

    useEffect(() => {
        props.getAllCart(userTg ? userTg.id : '11')
        setOpen(props.isOpen)
    }, [props.isOpen])

    function OrderProducts(e) {
        e.preventDefault()
        setHasError(false)
        const data = {
            user: userTg ? userTg.id : '11',
        }
        props.cartMsg(data)
        handleClose()
        onClose()
    }

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(!openTooltip);
    };

    const HtmlTooltip = styled(({className, ...props}) => (
        <Tooltip arrow {...props} classes={{popper: className}} onClose={handleTooltipClose}
                 open={openTooltip}
                 disableFocusListener
                 disableHoverListener
                 disableTouchListener/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    return (
        <div>
            {props.cart && props.cart.cart ?
                <Dialog
                    fullScreen
                    open={props.isOpen ? props.isOpen : false}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    className={'cart-modal'}
                >
                    <div className={'cart-header'}>
                        <h2 className="h2">{t('in_cart')} {cart ? cart.length : 0} {t("incart_end")}</h2>
                    </div>
                    {cart.map((item, index) =>
                        <div className={'cart-product'} key={index}>
                            <div className={'cart-product-photo'}>
                                <img src={item.item.photo} alt={item.item[`title_${i18n.language}`]}/>
                            </div>
                            <div className={'cart-product-body'}>
                                <h3>{item.item[`title_${i18n.language}`]}<DeleteForeverOutlinedIcon
                                    onClick={() => deleteProduct(item)}/>
                                </h3>
                                <div className={'addToCartBlock quantityBlock'}>
                                    <h4 className={'price-product'}>{getPrice(item)} {t('sum')}</h4>
                                    <div className={'counter'}>
                                        <button onClick={() => RemoveQuantity(item)}
                                                disabled={item.number_of_product === 1 ? true : false}
                                                className={`quantityBtn`}>-
                                        </button>
                                        <span className={'quantity'}>{item.number_of_product}</span>
                                        <button onClick={() => AddQuantity(item)} className={'quantityBtn'}>+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="checkout">
                        <button type="button" onClick={(e)=>OrderProducts(e)} className="btn btn__red btn__big _fw btn-order">
                            {t('order_now')}
                        </button>
                    </div>
                    <CloseButton onClick={handleClose} className={'close-btn'}/>
                </Dialog>
                : ''}
        </div>
    );
}

export default connect(props => props, {
    getAllCart, deleteCart, getMyUser, getAllOrders, cartMsg,
    editMyCart, ordersSave, messageReset
})(CartModal)
