import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {connect} from "react-redux";
import ImageGallery from 'react-image-gallery';
import {cartSave, getAllCart, messageReset} from "../../store/cart";
import {toast, ToastContainer} from "react-toastify";
import {useTelegram} from "../useTelegram";
import {useTranslation} from "react-i18next";
import "react-image-gallery/styles/css/image-gallery.css";


function ProductModal(props) {
    const {tg, userTg, onClose} = useTelegram()
    const {t, i18n} = useTranslation()
    const [show, setShow] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [priceProduct, setPriceProduct] = useState(0);

    useEffect(() => {
        if (props.message == 'deleted') {
            toast.success(t('product_deleted'), {theme: 'colored', draggable: false, autoClose: 1600, delay: 10})
            props.messageReset()
        } else if (props.message === 'error') {
            toast.error(t('error'), {theme: 'colored', draggable: false, autoClose: 1600})
            props.messageReset()
        } else if (props.message === true) {
            toast.success(t('product_added'), {theme: 'colored', draggable: false, autoClose: 1600, delay: 10})
            props.messageReset()
            props.getAllCart(userTg ? userTg.id : '11')
            props.setIsOpen(false)
        } else if (props.message === 'edited') {
            props.messageReset()
        }
    }, [props.message])

    useEffect(() => {
        setQuantity(1)
        setPriceProduct(props.orderItem.price)
    }, [props])


    function addToCart(item) {
        const date = new Date()
        var utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        var nd = new Date(utc + (3600000 * "+5"));
        // if (!(nd.getHours() >= 9 && nd.getHours() < 22)) {
        //     toast.error(t("time_work"), {theme: 'colored', draggable: false, autoClose: 3000, position: "top-center"})
        // }
        let data = {};
        data.buyer = userTg ? userTg.id : '11'
        data.quantity = quantity
        data.product = item.id
        props.cartSave(data)
        setPriceProduct(0)
    }

    function AddQuantity() {
        setQuantity(quantity + 1)
    }

    function RemoveQuantity() {
        setQuantity(quantity - 1)
    }

    function handleClose() {
        setShow(false);
        props.setIsOpen(false);
        setPriceProduct(0)
    }

    useEffect(() => {
        setShow(props.isOpen)
    }, [props.isOpen])

    let images = []

    if(props.orderItem.photos){
        let j = 0
        while (j < props.orderItem.photos.length + 1) {
            if (j === 0){
                let photo = {
                    original: props.orderItem.photo,
                    thumbnail: props.orderItem.photo,
                }
                images.push(photo)
            }
            else{
                let photo = {
                    original: props.orderItem.photos[j-1].photo,
                    thumbnail: props.orderItem.photos[j-1].photo,
                }
                images.push(photo)
            }
            j++
        }
    }

    return (
        <div>
            <ToastContainer/>
            {props.orderItem ?
                <Modal show={show} onHide={handleClose} fullscreen className={'product-order mt-3'}
                       aria-labelledby="contained-modal-title-vcenter">
                    <div>
                        <div className="price-sale">
                            {props.orderItem.price_sale ? <span
                                className={"on-sale c-green"}>{-Math.floor(100 * (props.orderItem.price_sale - props.orderItem.price) / props.orderItem.price_sale)}%</span> : ''}
                            {props.orderItem[`spec_text_${i18n.language}`] ? <span className={"on-sale"}>{props.orderItem[`spec_text_${i18n.language}`]}</span> : ''}
                        </div>
                        <ImageGallery items={images} showFullscreenButton={false} showThumbnails={false} showPlayButton={false} infinite={false} />
                        <h3>{props.orderItem[`title_${i18n.language}`]}</h3>
                        {props.orderItem[`description_${i18n.language}`] ?
                            <p>{props.orderItem[`description_${i18n.language}`]}</p> : ''}
                    </div>
                    <div className={'addToCartBlock'}>
                        {props.orderItem.price_sale ?
                        <p
                            className={"discount-price"}>{props.orderItem.price_sale ? props.orderItem.price_sale : ''} {t('sum')}</p> : ""}
                        <h4 className={'price-product'}>
                            {quantity * priceProduct} {t('sum')}
                        </h4>
                        <div className={'counter'}>
                            <p>{t('quantity')}</p>
                            <button onClick={RemoveQuantity} disabled={quantity === 1 ? true : false}
                                    className={`quantityBtn`}>-
                            </button>
                            <span className={'quantity'}>{quantity}</span>
                            <button onClick={AddQuantity} className={'quantityBtn'}>+</button>
                        </div>
                        <button className={'addToCartBtn'}
                                onClick={() => addToCart(props.orderItem)}>{t('add_to_cart')}
                        </button>
                    </div>
                    <Button variant="contained" className={'close-btn'}
                            onClick={handleClose}><CloseIcon/></Button>
                </Modal> : ''}

        </div>
    )
}

export default connect(props => props.cart, {cartSave, messageReset, getAllCart})(ProductModal)